import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import { MenuItemLink } from 'react-admin';

const useStyles = makeStyles({
  menuItem: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

/**
 * React admin Menu Item. For more information see react-admin.
 */
const PasswordChangeMenuItem = React.forwardRef<any, any>((props, ref) => {
  const classes = useStyles();
  return (
    <MenuItemLink
      ref={ref}
      sidebarIsOpen
      className={classes.menuItem}
      to="/change-password"
      primaryText="Change password"
      leftIcon={<SettingsIcon />}
      onClick={props.onClick}
    />
  );
});

export default PasswordChangeMenuItem;
