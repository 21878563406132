import React from 'react';
import { useAuthProvider, useNotify, useRedirect } from 'react-admin';
import { FormValues } from './types';

/**
 * Hook that set a new password for a user.
 */
const useUpdatePassword = () => {
  const authProvider = useAuthProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const setPassword = React.useCallback(
    async (values: FormValues) => {
      try {
        await authProvider.forgotPasswordSubmit(
          values.email,
          values.code,
          values.new_password,
        );
        redirect('/login');
        notify('Your password successfully reset.', 'success');
      } catch (error) {
        notify(
          error.message
            ? error.message
            : 'Something went wrong please try again later.',
          'error',
        );
      }
    },
    [authProvider, notify, redirect],
  );

  return {
    setPassword,
  };
};

export default useUpdatePassword;
