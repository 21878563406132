import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SaveButton as RaSaveButton, SaveButtonProps } from 'react-admin';

const useStyles = makeStyles(() => ({
  button: {
    background: 'linear-gradient(to top, #151719, #7c8792)',
  },
}));

const EmptyIcon = () => <div />;

/**
 * Save button without an icon and partially override styles.
 */
const SaveButton = (props: SaveButtonProps) => {
  const classes = useStyles();
  return (
    <RaSaveButton
      fullWidth
      className={classes.button}
      variant="contained"
      type="submit"
      icon={<EmptyIcon />}
      {...props}
    />
  );
};

export default SaveButton;
