import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DateRangeInput } from 'components/inputs';
import { ListControllerProps, Filter } from 'react-admin';
import { SummaryItem } from 'types';

const useStyles = makeStyles(() => ({
  filterForm: {
    justifyContent: 'center',
  },
}));

const FilterForm = (context: ListControllerProps<SummaryItem>) => {
  const classes = useStyles();
  return (
    <Filter
      {...context}
      variant="outlined"
      classes={{ form: classes.filterForm }}
    >
      <DateRangeInput
        alwaysOn
        margin="none"
        source="processed"
        label="Period"
      />
    </Filter>
  );
};

export default FilterForm;
