import React from 'react';
import { TextInput, PasswordInput, SimpleForm, required } from 'react-admin';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import FormLayout from 'components/FormLayout';
import FormToolbar from 'components/FormToolbar';
import { FormValues } from './types';
import useSignUp from './useSignUp';

const validateForm = (values: FormValues) => {
  const errors = {} as Record<string, string>;
  if (values.password !== values.confirm_password) {
    errors.confirm_password = 'Password does not match';
  }
  return errors;
};

/**
 * Sign Up page to register a new user.
 */
const SignUp = () => {
  const { singUp } = useSignUp();
  return (
    <FormLayout title="Sign Up">
      <SimpleForm
        variant="standard"
        save={singUp}
        validate={validateForm}
        toolbar={<FormToolbar label="Sign Up" />}
      >
        <TextInput
          isRequired
          validate={[required()]}
          label="Email"
          type="email"
          source="email"
        />
        <PasswordInput
          isRequired
          validate={[required()]}
          label="New Password"
          source="password"
        />
        <PasswordInput
          isRequired
          validate={[required()]}
          label="Confirm New Password"
          source="confirm_password"
        />
        <Typography
          display="block"
          variant="body1"
          component={Link}
          to="/login"
        >
          Already have an account?
        </Typography>
      </SimpleForm>
    </FormLayout>
  );
};

export default SignUp;
