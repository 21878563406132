import React from 'react';
import { useAuthProvider, useNotify, useRedirect } from 'react-admin';

export interface FormValues {
  email: string;
  code: number;
}

/**
 * Hook that send the request to reset the password.
 */
const useUpdatePassword = () => {
  const authProvider = useAuthProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const resetPassword = React.useCallback(
    async (values: FormValues) => {
      try {
        await authProvider.forgotPassword(values.email);
        redirect('/reset-password');
        notify('Please check your email for further instructions.', 'success');
      } catch (error) {
        notify(
          error.message
            ? error.message
            : 'We can not find your email, please check for a typo.',
          'error',
        );
      }
    },
    [authProvider, notify, redirect],
  );

  return {
    resetPassword,
  };
};

export default useUpdatePassword;
