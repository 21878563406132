/**
 * Contains all resources that backend currently has.
 */

const JOBS = 'jobs';
const TASKS = 'tasks';
const SUMMARY = 'summary';
const CLIENTS = 'clients';
const CONTACT = 'contact';

const resources = {
  JOBS,
  TASKS,
  SUMMARY,
  CLIENTS,
  CONTACT,
};

export default resources;
