import React from 'react';
import { Record, SaveButton, Toolbar, ToolbarProps } from 'react-admin';

const FormToolbar = (props: ToolbarProps<Record>) => {
  // transform to any due to bug in typescript props definition
  return (
    <Toolbar {...(props as any)}>
      <SaveButton label="Submit" redirect="/" submitOnEnter={true} />
    </Toolbar>
  );
};

export default FormToolbar;
