import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Questions, Form } from './components';
import Typography from '@material-ui/core/Typography';

const FaqPage = () => {
  return (
    <Grid container direction="column" alignContent="center">
      <Grid item>
        <Typography variant="h6" component="h3" align="center">
          Frequently asked questions
        </Typography>
      </Grid>
      <Grid container item justify="center" xs={12}>
        <Grid md={6} xs={12}>
          <Questions />
        </Grid>
      </Grid>
      <Grid container item justify="center" xs={12}>
        <Grid md={6} xs={12}>
          <Form />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FaqPage;
