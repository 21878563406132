import React from 'react';
import { Pagination as RaPagination, PaginationProps } from 'react-admin';

/**
 * Pagination that stores values that can be sent to backend.
 */
const Pagination = (props: PaginationProps) => (
  <RaPagination {...props} rowsPerPageOptions={[5, 10, 20, 30, 40, 50]} />
);

export default Pagination;
