import React from 'react';
import { Admin, DataProvider, Resource } from 'react-admin';
import { createBrowserHistory as createHistory } from 'history';
import { AuthProvider, ServiceProvider } from 'providers';
import { resources } from 'appConstants';
import { Dashboard, Login } from 'pages';
import Layout from 'layout';
import routes from 'routes';
import { jobs } from 'resources';
import theme from 'theme';
import './index.css';

/**
 * Main React Admin configuration. There we define required resources, layouts, login page, routes and so on.
 * For more information see react-admin docs.
 */
const App = () => (
  <Admin
    layout={Layout}
    dashboard={Dashboard}
    authProvider={new AuthProvider()}
    loginPage={Login}
    theme={theme}
    history={createHistory()}
    customRoutes={routes}
    dataProvider={new ServiceProvider() as DataProvider}
  >
    <Resource name={resources.SUMMARY} />
    <Resource name={resources.JOBS} {...jobs} />
    <Resource name={resources.TASKS} />
    <Resource name={resources.CLIENTS} />
  </Admin>
);

export default App;
