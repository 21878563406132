import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import {
  Filter,
  FilterProps,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import { resources } from 'appConstants';
import { DateRangeInput } from 'components/inputs';

const stateChoices = [
  { id: 'submitted', name: 'Submitted' },
  { id: 'queued', name: 'Queued' },
  { id: 'generating', name: 'Generating' },
  { id: 'returned', name: 'Returned' },
  { id: 'archived', name: 'Archived' },
  { id: 'printed', name: 'Printed' },
  { id: 'failed', name: 'Failed' },
  { id: 'held', name: 'Held' },
  { id: 'cancelled', name: 'Cancelled' },
];

const useStyles = makeStyles(() => ({
  input: {
    minHeight: 52,
  },
}));

/**
 * Filters that user can use to filter job resources.
 */
const Filters = (props: Omit<FilterProps, 'children'>) => {
  const classes = useStyles();
  return (
    <Filter {...props} variant="outlined">
      <AutocompleteArrayInput
        alwaysOn
        allowEmpty={false}
        margin="none"
        label="State"
        source="state"
        choices={stateChoices}
      />
      <ReferenceArrayInput
        alwaysOn
        source="client_sid"
        reference={resources.CLIENTS}
      >
        <AutocompleteArrayInput
          margin="none"
          allowEmpty={false}
          label="Client"
          source="client"
          optionText="name"
        />
      </ReferenceArrayInput>
      <DateRangeInput
        alwaysOn
        margin="none"
        className={classes.input}
        source="created"
        label="Period"
      />
    </Filter>
  );
};

export default Filters;
