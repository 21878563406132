import React from 'react';
import { useAuthProvider, useNotify, useRedirect } from 'react-admin';

export interface FormValues {
  email: string;
  code: number;
}

/**
 * hook that confirm user account.
 */
const useConfirmSignUp = () => {
  const authProvider = useAuthProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const verify = React.useCallback(
    async (values: FormValues) => {
      try {
        await authProvider.verify(values.email, values.code);
        redirect('/login');
        notify('Your successfully approved your identity.', 'success');
      } catch (error) {
        notify(
          error.message
            ? error.message
            : 'Code is wrong. Please ensure that you type correct code',
          'error',
        );
      }
    },
    [authProvider, notify, redirect],
  );

  return {
    verify,
  };
};

export default useConfirmSignUp;
