import React from 'react';
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from 'recharts';

interface ChartItem {
  name: string;
  documents: number;
  views: number;
}

interface LinearChartProps {
  data: ChartItem[];
}

/**
 * Line chart that shows documents and views for more information see recharts.
 */
const LinearChart = ({ data }: LinearChartProps) => (
  <ResponsiveContainer height={300}>
    <LineChart width={400} height={400} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="documents" stroke="#8884d8" />
      <Line type="monotone" dataKey="views" stroke="#82ca9d" />
    </LineChart>
  </ResponsiveContainer>
);

export default LinearChart;
