import React from 'react';
import {
  Bar,
  BarChart as RechartsBarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

interface ChartItem {
  name: string;
  documents: number;
  views: number;
}

interface BarChartProps {
  data: ChartItem[];
}

/**
 * Bar chart that shows documents and views for more information see recharts.
 */
const BarChart = ({ data }: BarChartProps) => (
  <ResponsiveContainer height={300}>
    <RechartsBarChart width={400} height={400} data={data}>
      <CartesianGrid horizontal={false} strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <ReferenceLine y={0} stroke="#000" />
      <Bar dataKey="documents" stackId="a" fill="#8884d8" />
      <Bar dataKey="views" stackId="b" fill="#82ca9d" />
    </RechartsBarChart>
  </ResponsiveContainer>
);

export default BarChart;
