import get from 'lodash/get';

const getValue = (name: string, defaultValue: any = null): any => {
  return get(process.env, `REACT_APP_${name}`, defaultValue);
};

export interface Config {
  appMode: 'dev' | 'prod' | 'test';
  baseUrl: string;
  isProduction: () => boolean;
  awsCognito: {
    identityPoolId: string;
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
  };
}

const config: Config = {
  appMode: getValue('MODE', 'dev'),
  baseUrl: getValue('URL', 'https://api.dev.clouddirect.biz/v1'),
  isProduction: (): boolean => {
    return config.appMode === 'prod';
  },
  awsCognito: {
    identityPoolId: getValue('AWS_COGNITO_IDENTITY_POOL_ID', ''),
    region: getValue('AWS_COGNITO_REGION', 'ap-southeast-2'),
    userPoolId: getValue('AWS_USER_POOL_ID'),
    userPoolWebClientId: getValue('AWS_USER_POOL_WEB_CLIENT_ID'),
  },
};

export default config;
