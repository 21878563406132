import { Theme, withStyles } from '@material-ui/core/styles';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';

/**
 * Material UI AccordionSummary component with override styles, for more information
 * check original material ui docs of AccordionSummary component.
 */
const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#404040',
    color: theme.palette.common.white,
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {
    background: '#737373',
  },
}))(MuiAccordionSummary);

export default AccordionSummary;
