import moment from 'moment';
import {
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
  UpdateManyParams,
  CreateParams,
  CreateResult,
  DeleteResult,
  DeleteParams,
  DeleteManyParams,
  DeleteManyResult,
  UpdateManyResult,
  fetchUtils,
} from 'react-admin';
import { stringify } from 'query-string';
import config from 'config';
import { resources } from 'appConstants';
import { Auth } from 'aws-amplify';

const fetch = async (url: string, options: fetchUtils.Options = {}) => {
  if (!options.headers) {
    const session = await Auth.currentSession();
    options.headers = new Headers({
      Accept: 'application/json',
      Authorization: `Bearer ${session.getAccessToken().getJwtToken()}`,
      'Content-Type': 'application/json',
    });
  }
  return fetchUtils
    .fetchJson(config.baseUrl + url, options)
    .then((response) => response.json);
};

const getApiEndpointForResource = (resource: string) =>
  ({
    [resources.SUMMARY]: '/summary',
    [resources.CLIENTS]: '/clients',
    [resources.JOBS]: '/jobs',
    [resources.TASKS]: '/tasks',
    [resources.CONTACT]: '/contact',
  }[resource]);

/**
 * Service Provider that implements interaction with the backend side.
 */
class ServiceProvider {
  getList<RecordType extends Record<string, any> = Record<string, any>>(
    resource: string,
    params: GetListParams,
  ): Promise<GetListResult<RecordType>> {
    const {
      filter,
      pagination: { page, perPage },
      // sort: { field, order },
    } = params;
    const query = {
      page: resource !== resources.SUMMARY ? page : undefined,
      ...filter,
      per_page:
        [resources.CLIENTS, resources.SUMMARY].indexOf(resource) === -1
          ? perPage
          : undefined,
      // ordering: `${order === 'ASC' ? '' : '-'}${field}`,
      ...Object.keys(filter).reduce((prevFilters, filterName) => {
        if (
          Array.isArray(filter[filterName]) &&
          filter[filterName].length === 2 &&
          moment(filter[filterName][0], moment.ISO_8601, true).isValid()
        ) {
          return {
            ...prevFilters,
            [filterName]: [
              `ge.${moment(filter[filterName][0])
                .utc()
                .format('YYYY-MM-DDThh:mm:ss')}`,
              `lt.${moment(filter[filterName][1])
                .utc()
                .format('YYYY-MM-DDThh:mm:ss')}`,
            ],
          };
        }
        return {
          ...prevFilters,
          [filterName]: filter[filterName],
        };
      }, {}),
    };
    return fetch(`${getApiEndpointForResource(resource)}?${stringify(query)}`, {
      method: 'GET',
    }).then((result: Record<string, any>) => {
      if (resource == resources.SUMMARY) {
        return {
          data: [
            ({ id: result.task as string, ...result } as unknown) as RecordType,
          ],
          total: 1,
        } as GetListResult<RecordType>;
      }
      return {
        data: result.results,
        total: result._pagination
          ? result._pagination.total
          : result.results.length,
      } as GetListResult<RecordType>;
    });
  }
  getOne<RecordType extends Record<string, any> = Record<string, any>>(
    resource: string,
    params: GetOneParams,
  ): Promise<GetOneResult<RecordType>> {
    return fetch(
      resource === resources.SUMMARY
        ? getApiEndpointForResource(resource)
        : `${getApiEndpointForResource(resource)}/${params.id}`,
      {
        method: 'GET',
      },
    ).then((record: RecordType) => {
      if (resource === resources.SUMMARY) {
        return {
          data: {
            id: record.task,
            ...record,
          },
        };
      }
      if (resource === resources.TASKS) {
        return {
          data: record.result,
        };
      }

      return {
        data: record,
      };
    });
  }

  getMany<RecordType extends Record<string, any> = Record<string, any>>(
    resource: string,
    params: GetManyParams,
  ): Promise<GetManyResult<RecordType>> {
    return Promise.reject();
  }

  getManyReference<
    RecordType extends Record<string, any> = Record<string, any>
  >(
    resource: string,
    params: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<RecordType>> {
    return Promise.reject();
  }

  update<RecordType extends Record<string, any> = Record<string, any>>(
    resource: string,
    params: UpdateParams,
  ): Promise<UpdateResult<RecordType>> {
    return Promise.reject();
  }

  updateMany<RecordType extends Record<string, any> = Record<string, any>>(
    resource: string,
    params: UpdateManyParams<Partial<RecordType>>,
  ): Promise<UpdateManyResult> {
    return Promise.reject();
  }

  create<RecordType extends Record<string, any> = Record<string, any>>(
    resource: string,
    params: CreateParams,
  ): Promise<CreateResult<RecordType>> {
    return fetch(getApiEndpointForResource(resource), {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then((data) => ({
      data: {
        id: data.MessageId,
        ...data,
      },
    }));
  }

  delete<RecordType extends Record<string, any> = Record<string, any>>(
    resource: string,
    params: DeleteParams,
  ): Promise<DeleteResult<RecordType>> {
    return Promise.reject();
  }

  deleteMany(
    resource: string,
    params: DeleteManyParams,
  ): Promise<DeleteManyResult> {
    return Promise.reject();
  }
}

export default ServiceProvider;
