import React from 'react';
import { UserMenu as RaUserMenu } from 'react-admin';
import PasswordChangeMenuItem from './PasswordChangeMenuItem';

interface UserMenuProps {
  label?: string;
  logout?: React.ReactElement;
  icon?: React.ReactNode;
}

/**
 * React admin UserMenu with additional PasswordChangeMenuItem. For more information see react-admin docs.
 */
const UserMenu = (props: UserMenuProps) => (
  <RaUserMenu {...props}>
    <PasswordChangeMenuItem />
  </RaUserMenu>
);

export default UserMenu;
