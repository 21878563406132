import React from 'react';
import { defaultTheme, Notification } from 'react-admin';
import Container from '@material-ui/core/Container';
import {
  ThemeProvider,
  Theme,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundImage: 'linear-gradient(to top, #151719, #7c8792)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    marginTop: theme.spacing(2),
    minWidth: 300,
  },
}));

interface FormLayoutProps {
  children: React.ReactNode;
  title: string;
}

/**
 * Base layout in case of Form.
 */
const FormLayout = ({ children, title }: FormLayoutProps) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={createMuiTheme(defaultTheme)}>
      <Container maxWidth={false} className={classes.main}>
        <Card className={classes.card}>
          <CardHeader title={title} />
          {children}
        </Card>
        <Notification />
      </Container>
    </ThemeProvider>
  );
};

export default FormLayout;
