import React from 'react';
import {
  Record,
  SaveButton,
  Button,
  Toolbar,
  ToolbarProps,
  useRedirect,
} from 'react-admin';

/**
 * Change Password Form Toolbar.
 */
const ChangePasswordToolbar = (props: ToolbarProps<Record>) => {
  const redirect = useRedirect();
  const cancel = React.useCallback(() => {
    redirect('/');
  }, [redirect]);

  // transform to any due to bug in typescript props definition
  return (
    <Toolbar {...(props as any)}>
      <SaveButton redirect="/" submitOnEnter={true} />
      <Button label="Cancel" variant="text" onClick={cancel} />
    </Toolbar>
  );
};

export default ChangePasswordToolbar;
