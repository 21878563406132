import * as React from 'react';
import { Field, withTypes } from 'react-final-form';
import { Link, useLocation } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useLogin, useNotify, Notification } from 'react-admin';

import logo from 'assets/logo.png';
import lock from 'assets/lock.png';
import Input from './Input';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundImage: 'linear-gradient(to top, #151719, #7c8792)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  gridMaxWidth: {
    maxWidth: 400,
  },
  logo: {
    marginTop: theme.spacing(6),
  },
  card: {
    background: 'transparent',
    marginTop: theme.spacing(2),
    minWidth: 400,
    boxShadow: 'none',
  },
  form: {
    padding: theme.spacing(0, 1, 1, 1),
  },
  input: {
    background: theme.palette.common.white,
    borderRadius: 4,
    padding: theme.spacing(2.25, 1.75),
    marginTop: theme.spacing(1),
  },
  notchedOutline: {
    border: 0,
  },
  actions: {
    // padding: theme.spacing(2, 3, 2, 3),
    background: 'transparent',
  },
  button: {
    background: 'linear-gradient(to top, #151719, #7c8792)',
  },
}));

interface FormValues {
  username?: string;
  password?: string;
}

const { Form } = withTypes<FormValues>();

/**
 * Login Form.
 */
const Login = () => {
  const [loading, setLoading] = React.useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation<{ nextPathname: string } | null>();

  const handleSubmit = (auth: FormValues) => {
    setLoading(true);
    login(auth, location.state ? location.state.nextPathname : '/').catch(
      (error: Error) => {
        setLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          'warning',
          {
            _:
              typeof error === 'string'
                ? error
                : error && error.message
                ? error.message
                : undefined,
          },
        );
      },
    );
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      validateOnBlur={false}
      render={({ handleSubmit }: any) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Grid
              item
              container
              className={classes.gridMaxWidth}
              alignItems="center"
              justify="space-between"
            >
              <Grid item>
                <img
                  className={classes.logo}
                  src={logo}
                  alt="CloudDirectLogo"
                />
              </Grid>
              <Grid item>
                <img className={classes.logo} src={lock} alt="Lock" />
              </Grid>
            </Grid>

            <Card className={classes.card}>
              <div className={classes.form}>
                <Field
                  type="email"
                  name="username"
                  placeholder="Email Address"
                  component={Input}
                  disabled={loading}
                  InputProps={{
                    classes: {
                      input: classes.input,
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
                <Field
                  name="password"
                  placeholder={translate('ra.auth.password')}
                  component={Input}
                  type="password"
                  disabled={loading}
                  InputProps={{
                    classes: {
                      input: classes.input,
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
              </div>
              <CardActions className={classes.actions}>
                <Grid
                  container
                  direction="column"
                  alignItems="stretch"
                  spacing={1}
                >
                  <Grid item container>
                    <Button
                      fullWidth
                      // className={classes.button}
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      type="submit"
                    >
                      {loading && <CircularProgress size={25} thickness={2} />}
                      {translate('ra.auth.sign_in')}
                    </Button>
                  </Grid>
                  <Grid item container>
                    <Button
                      fullWidth
                      //className={classes.button}
                      variant="contained"
                      type="submit"
                      color="primary"
                      component={Link}
                      to="/forgot-password"
                      disabled={loading}
                    >
                      Forgot your password?
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

export default Login;
