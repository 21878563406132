import React from 'react';
import moment from 'moment';
import {
  ListControllerProps,
  useDataProvider,
  useListController,
} from 'react-admin';
import { resources } from 'appConstants';
import { SummaryItem, Task } from 'types';

export interface ChartItem {
  name: string;
  documents: number;
  views: number;
}

export interface DashboardState {
  summary?: SummaryItem;
  task?: Task;
  chartData: ChartItem[];
}

export interface DashboardStateResult {
  state: DashboardState;
  context: ListControllerProps<SummaryItem>;
}

/**
 * Hook that retrieve the information from summary and based on it get current running task and
 * retrieve the task progress each 3 seconds.
 */
const useDashboardState = (): DashboardStateResult => {
  const latestUpdate = React.useRef<string[]>([]);
  const intervalRef = React.useRef<any>(undefined);
  const [state, setState] = React.useState<DashboardState>({
    chartData: [],
  });
  const context = useListController<SummaryItem>({
    resource: resources.SUMMARY,
    hasCreate: false,
    hasEdit: false,
    hasShow: false,
    hasList: true,
    basePath: '/',
    filterDefaultValues: {
      processed: [
        moment().startOf('month').subtract(6, 'months').toDate(),
        moment().endOf('month').toDate(),
      ],
    },
    syncWithLocation: true,
  });
  const dataProvider = useDataProvider();

  const fetchTask = React.useCallback(
    async (taskId: string) => {
      if (intervalRef.current) {
        clearTimeout(intervalRef.current);
      }
      intervalRef.current = 1;
      const { data: task } = await dataProvider.getOne<Task>(resources.TASKS, {
        id: taskId,
      });
      setState({
        task,
        chartData:
          task.state === 'completed' && task.data.results
            ? task.data.results.reverse().map(
                (item) =>
                  ({
                    name: moment(item.name, 'YYYY-MM').format('MMM'),
                    documents: item.document_count,
                    views: item.view_count,
                  } as ChartItem),
              )
            : [
                {
                  name: moment().format('MMM'),
                  documents: 0,
                  views: 0,
                },
              ],
      });
      if (task.state !== 'completed') {
        intervalRef.current = setTimeout(() => fetchTask(taskId), 3e3);
      }
    },
    [dataProvider, setState, intervalRef],
  );

  React.useEffect(() => {
    const newData = Object.keys(context.data);
    if (JSON.stringify(latestUpdate.current) === JSON.stringify(newData))
      return;
    latestUpdate.current = newData;
    newData.forEach(async (taskId) => {
      await fetchTask(taskId);
    });
  }, [context, latestUpdate]);
  return { state, context };
};

export default useDashboardState;
