import React from 'react';
import { Datagrid, DateField, TextField, ListProps } from 'react-admin';
import List from 'components/List';
import ElapsedField from 'components/ElapsedField';
import ListActions from './ListActions';
import Filters from './Filters';

/**
 * Job List page that allows to see jobs that match to filter criteria.
 */
const JobList = (props: ListProps) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<Filters />}
    exporter={false}
    actions={<ListActions />}
  >
    <Datagrid>
      <TextField label="ClientID" source="client.sid" />
      <TextField label="JobID" source="sid" />
      <TextField label="Document" source="document" />
      <DateField showTime label="Start Time" source="created" />
      <ElapsedField label="Elapsed/End Time" sources={['created', 'updated']} />
      <TextField label="State" source="state" />
      <TextField label="Priority" source="priority" />
      <TextField label="Count" source="document_count" />
    </Datagrid>
  </List>
);

export default JobList;
