import Amplify, { Auth } from 'aws-amplify';
import config from 'config';

Amplify.configure({
  Auth: config.awsCognito,
  authenticationFlowType: 'USER_SRP_AUTH',
});

/**
 * Auth Provider that implements interaction with aws amplify.
 */
class AuthProvider {
  // authentication
  login({ username, password }: { username: string; password: string }) {
    return Auth.signIn(username, password);
  }

  checkError(error: any) {
    return Promise.resolve();
  }

  checkAuth(params: any) {
    return Auth.currentAuthenticatedUser();
  }

  changePassword(oldPassword: string, newPassword: string) {
    return Auth.currentAuthenticatedUser().then((user) =>
      Auth.changePassword(user, oldPassword, newPassword),
    );
  }

  forgotPassword(email: string) {
    return Auth.forgotPassword(email);
  }

  forgotPasswordSubmit(email: string, code: string, newPassword: string) {
    return Auth.forgotPasswordSubmit(email, code, newPassword);
  }

  signUp(email: string, password: string) {
    return Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
      },
    });
  }

  verify(username: string, code: string) {
    return Auth.confirmSignUp(username, code);
  }

  logout() {
    return Auth.signOut({ global: true });
  }

  getIdentity() {
    return Auth.currentAuthenticatedUser().then(({ attributes }) => ({
      ...attributes,
      fullName: attributes.email,
    }));
  }

  // authorization
  getPermissions(params: any) {
    return Promise.resolve();
  }
}

export default AuthProvider;
