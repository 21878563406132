import React from 'react';
import get from 'lodash/get';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

interface GridFieldProps {
  record: Record<string, any>;
  label?: string;
  source: string;
  defaultValue?: string;
}

/**
 * Grid field that uses Grid component to output information.
 */
const GridField = ({
  record,
  label,
  source,
  defaultValue = '',
}: GridFieldProps) => (
  <Grid item container justify="space-between">
    <Grid item>
      <Typography>{label}:&nbsp;</Typography>
    </Grid>
    <Grid item>
      <Typography align="right">
        {get(record, source, defaultValue) || defaultValue}
      </Typography>
    </Grid>
  </Grid>
);

export default GridField;
