import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';
import TextField from '@material-ui/core/TextField';

/**
 * Input component that render TextField for react-final-form.
 */
const Input = ({
  meta: { touched, error },
  input: inputProps,
  ...props
}: FieldRenderProps<string>) => (
  <TextField
    fullWidth
    variant="outlined"
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);

export default Input;
