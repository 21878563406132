import React from 'react';

interface AccordionGroupProps {
  children: React.ReactNode;
}

/**
 * AccordionGroup component that has to contain only Accordion component
 * and controls their state which ensure that only one component can be open.
 */
const AccordionGroup = ({ children }: AccordionGroupProps) => {
  const [expanded, setExpanded] = React.useState<number>(-1);

  const handleChange = React.useCallback(
    (panel: number) => (event: object, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : -1);
    },
    [setExpanded],
  );

  const isExpanded = React.useCallback(
    (panel: number) => {
      return expanded === panel;
    },
    [expanded],
  );

  return (
    <React.Fragment>
      {React.Children.map(children, (child, idx) =>
        React.cloneElement(child as React.ReactElement, {
          ...(child as React.ReactElement).props,
          onChange: handleChange(idx),
          expanded: isExpanded(idx),
        }),
      )}
    </React.Fragment>
  );
};

export default AccordionGroup;
