import React from 'react';
import {
  CRUD_CREATE,
  useCreate,
  useGetIdentity,
  useNotify,
  useRedirect,
} from 'react-admin';
import { resources } from 'appConstants';

interface FormValues {
  email_body: string;
}

/**
 * hook that send form data to the server and notify an user in case of success or failure.
 */
const useSubmitQuestion = () => {
  const { identity } = useGetIdentity();
  const notify = useNotify();
  const redirect = useRedirect();

  const email_sender = React.useMemo(() => {
    if (!identity) return '';

    return identity.email || '';
  }, [identity]);

  const [create, { loading }] = useCreate(resources.CONTACT);

  const submit = React.useCallback(
    ({ email_body }: FormValues) => {
      if (!email_sender) return null;

      return create(
        {
          payload: {
            data: {
              email_subject: 'Enquiry on CloudDirect',
              email_body,
              email_sender,
            },
          },
        },
        {
          action: CRUD_CREATE,
          onSuccess: () => {
            notify('Email was sent to support team.', 'success');
            redirect('/');
          },
          onFailure: () => {
            notify('Something went wrong, please try again later.', 'error');
          },
        },
      );
    },
    [email_sender, create, redirect, notify],
  );

  return { submit, loading: email_sender === '' ? true : loading };
};

export default useSubmitQuestion;
