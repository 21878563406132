import React from 'react';
import {
  TextInput,
  SimpleForm,
  required,
  PasswordInput,
  minLength,
} from 'react-admin';
import FormLayout from 'components/FormLayout';
import FormToolbar from 'components/FormToolbar';
import { FormValues } from './types';
import useSetNewPassword from './useSetNewPassword';

const validateForm = (values: FormValues) => {
  const errors = {} as Record<string, string>;
  if (values.new_password !== values.confirm_password) {
    errors.confirm_password = 'Password does not match';
  }
  return errors;
};

/**
 * Reset Password page. Allows to set a new password for a user.
 */
const ResetPassword = () => {
  const { setPassword } = useSetNewPassword();
  return (
    <FormLayout title="Reset your password.">
      <SimpleForm
        variant="standard"
        save={setPassword}
        validate={validateForm}
        toolbar={<FormToolbar label="Set new password" />}
      >
        <TextInput
          isRequired
          validate={[required()]}
          label="Email"
          type="email"
          source="email"
        />
        <TextInput
          isRequired
          validate={[required()]}
          label="Code"
          source="code"
        />
        <PasswordInput
          isRequired
          validate={[required(), minLength(6)]}
          label="New Password"
          source="new_password"
        />
        <PasswordInput
          isRequired
          validate={[required(), minLength(6)]}
          label="Confirm New Password"
          source="confirm_password"
        />
      </SimpleForm>
    </FormLayout>
  );
};

export default ResetPassword;
