import React from 'react';
import { AppBar as RaAppBar, AppBarProps } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import UserMenu from './UserMenu';

const useStyles = makeStyles({
  title: {
    display: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
});

/**
 * React admin AppBar layout. For more information see react-admin.
 */
const AppBar = (props: AppBarProps) => {
  const classes = useStyles();
  return (
    <RaAppBar {...props} elevation={1} userMenu={<UserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.spacer} />
    </RaAppBar>
  );
};

export default AppBar;
