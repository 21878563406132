import React from 'react';
import moment from 'moment';
import get from 'lodash/get';

interface ElapsedFieldProps {
  label: string;
  record?: Record<string, any>;
  sources: string[];
}

/**
 * ElapsedField component that gets information from a record based on sources
 * and calculate the diff in time format.
 */
const ElapsedField = ({ record, sources }: ElapsedFieldProps) => {
  const [first, second] = sources;
  const diff = Math.abs(
    moment(get(record, first)).diff(moment(get(record, second))),
  );
  return <span>{moment.utc(diff).format('HH:mm:ss')}</span>;
};

export default ElapsedField;
