import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';

/**
 * Material UI AccordionDetails component with override styles, for more information
 * check original material ui docs of AccordionDetails component.
 */
const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2, 3, 2),
  },
}))(MuiAccordionDetails);

export default AccordionDetails;
