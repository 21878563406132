import React from 'react';
import { TextInput, SimpleForm, required } from 'react-admin';
import FormLayout from 'components/FormLayout';
import FormToolbar from 'components/FormToolbar';
import useForgotPassword from './useForgotPassword';

/**
 * Forgot Password page.
 */
const ForgotPassword = () => {
  const { resetPassword } = useForgotPassword();
  return (
    <FormLayout title="Forgot your password?">
      <SimpleForm
        variant="standard"
        save={resetPassword}
        toolbar={<FormToolbar label="Reset your password" />}
      >
        <TextInput
          isRequired
          validate={[required()]}
          label="Email"
          type="email"
          source="email"
        />
      </SimpleForm>
    </FormLayout>
  );
};

export default ForgotPassword;
