import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {
  TopToolbar,
  ListActionsProps,
  useListContext,
  sanitizeListRestProps,
} from 'react-admin';

const useStyles = makeStyles(() => ({
  button: {
    background: 'linear-gradient(to right, #3a3633 93%, #2a2725 100%)',
    color: '#fff',
    '&:hover': {
      color: '#fff',
    },
  },
}));

/**
 * Actions that attached to filters.
 */
const ListActions = ({
  className,
  exporter,
  filters,
  ...rest
}: ListActionsProps) => {
  const { setFilters } = useListContext();
  const classes = useStyles();
  const clearFilters = React.useCallback(() => {
    setFilters({}, {});
  }, [setFilters]);
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={clearFilters}
      >
        Clear All
      </Button>
    </TopToolbar>
  );
};

export default ListActions;
