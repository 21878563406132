import React from 'react';
import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import FormHelperText, {
  FormHelperTextProps,
} from '@material-ui/core/FormHelperText';
// @ts-ignore
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { useInput } from 'react-admin';
import './daterange.css';

type BaseDateRangeInputProps = Omit<FormControlProps, 'classes' | 'children'> &
  Record<string, any>;

export interface DateRangeInputProps extends Partial<BaseDateRangeInputProps> {
  InputLabelProps?: InputLabelProps;
  FormHelperTextProps?: FormHelperTextProps;
  label?: string;
  id?: string;
  helperText?: string;
  source: string;
  alwaysOn?: boolean;
}

const sanitizeProps = ({
  color,
  disabled,
  error,
  focused,
  fullWidth,
  hiddenLabel,
  margin = 'dense',
  required,
  size,
  variant = 'filled',
  ...dateRangeProps
}: BaseDateRangeInputProps) => {
  return {
    formControlProps: {
      color,
      disabled,
      error,
      focused,
      fullWidth,
      hiddenLabel,
      margin,
      required,
      size,
      variant,
    },
    dateRangeProps: {
      disableClock: true,
      ...dateRangeProps,
    },
  };
};

/**
 * React admin form input that allows to set date range.
 */
const DateRangeInput = ({
  alwaysOn,
  defaultValue,
  source,
  label,
  id,
  helperText,
  InputLabelProps = {},
  FormHelperTextProps = {},
  ...restProps
}: DateRangeInputProps) => {
  const { formControlProps, dateRangeProps } = sanitizeProps(
    restProps as BaseDateRangeInputProps,
  );
  const {
    input,
    meta: { error },
  } = useInput({
    defaultValue,
    id,
    name: source,
    isRequired: formControlProps.required,
    source,
  });
  const value = React.useMemo(() => {
    if (Array.isArray(input.value)) {
      return input.value.map((value) => new Date(value));
    }
    return null;
  }, [input]);
  const onChange = React.useCallback(
    (value) => {
      input.onChange(value ? value.filter((value: string) => !!value) : value);
    },
    [input],
  );
  return (
    <FormControl {...formControlProps} error={error}>
      {label && (
        <InputLabel
          shrink
          htmlFor={id}
          className="ra-calendar-label"
          {...InputLabelProps}
        >
          {label}
        </InputLabel>
      )}
      <DateRangePicker
        {...dateRangeProps}
        {...input}
        onChange={onChange}
        value={value}
      />
      <FormHelperText {...FormHelperTextProps}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default DateRangeInput;
