import React from 'react';
import { required, SimpleForm, TextInput } from 'react-admin';
import Toolbar from './Toolbar';
import useSubmitQuestion from './useSubmitQuestion';

/**
 * FAQ Form for the FAQ page.
 */
const Form = () => {
  const { submit, loading } = useSubmitQuestion();
  return (
    <SimpleForm
      save={submit}
      saving={loading}
      validateOnBlur={false}
      toolbar={<Toolbar />}
    >
      <TextInput
        fullWidth
        multiline
        rows={5}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        label="Not covered here? Ask anyway"
        source="email_body"
        validate={required()}
      />
    </SimpleForm>
  );
};

export default Form;
