import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import {
  useGetResourceLabel,
  getResources,
  ReduxState,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
} from 'react-admin';
import { Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import DefaultIcon from '@material-ui/icons/ViewList';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

/**
 * React admin Menu. For more information see react-admin.
 */
const Menu: React.FC<MenuProps> = ({
  onMenuClick,
  hasDashboard,
  logout,
  dense = false,
}: MenuProps) => {
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs'),
  );
  const resources = useSelector(getResources, shallowEqual) as Array<any>;
  const getResourceLabel = useGetResourceLabel();
  return (
    <Box mt={1}>
      {' '}
      {hasDashboard && (
        <DashboardMenuItem
          onClick={onMenuClick}
          dense={dense}
          sidebarIsOpen={open}
        />
      )}
      {resources
        .filter((r) => r.hasList)
        .map((resource) => (
          <MenuItemLink
            key={resource.name}
            to={{
              pathname: `/${resource.name}`,
              state: { _scrollToTop: true },
            }}
            primaryText={getResourceLabel(resource.name, 2)}
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            dense={dense}
            sidebarIsOpen={open}
          />
        ))}
      <MenuItemLink
        sidebarIsOpen={open}
        to={{
          pathname: '/faq',
          state: { _scrollToTop: true },
        }}
        primaryText="FAQ"
        leftIcon={<HelpOutlineIcon />}
        dense={dense}
        onClick={onMenuClick}
      />
      {isXSmall && logout}
    </Box>
  );
};

export default Menu;
