import React from 'react';
import { Layout as RaLayout, LayoutProps, Sidebar } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';

const CustomSidebar = (props: any) => <Sidebar {...props} size={200} />;

/**
 * React admin Layout. For more information see react-admin.
 */
const Layout = (props: LayoutProps) => (
  <RaLayout {...props} appBar={AppBar} menu={Menu} sidebar={CustomSidebar} />
);

export default Layout;
