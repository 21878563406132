import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import { Task } from 'types';
import GridField from 'components/GridField';

interface TaskCardProps {
  record?: Task;
  cloudDirectUserRole?: boolean;
}

/**
 * Task card that shows information about current task.
 */
const TaskCard = ({ record, cloudDirectUserRole }: TaskCardProps) => (
  <Card>
    <CardHeader title={cloudDirectUserRole ? 'Summary' : 'Company Summary'} />
    <CardContent>
      {record ? (
        <Grid container direction="column">
          <GridField
            label={cloudDirectUserRole ? 'Clients' : 'Jobs Processed'}
            record={record}
            source="data.summary.client_count"
            defaultValue="0"
          />
          <GridField
            label={cloudDirectUserRole ? 'Archives' : 'Documents Generated'}
            record={record}
            source="data.summary.archive_count"
            defaultValue="0"
          />
          <GridField
            label={cloudDirectUserRole ? 'Documents' : 'Documents Stored'}
            record={record}
            source="data.summary.document_count"
            defaultValue="0"
          />
          <GridField
            label="Views / Retrievals"
            record={record}
            source="data.summary.view_count"
            defaultValue="0"
          />
          <GridField label="State" record={record} source="state" />
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </CardContent>
  </Card>
);

export default TaskCard;
