import * as React from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import theme from 'theme';
import { LoginForm } from './components';

/**
 * Login page.
 */
const Login = () => (
  <ThemeProvider theme={createMuiTheme(theme)}>
    <LoginForm />
  </ThemeProvider>
);

export default Login;
