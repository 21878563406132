import React from 'react';
import Accordion from 'components/Accordion';
import AccordionDetails from 'components/AccordionDetails';
import AccordionGroup from 'components/AccordionGroup';
import AccordionSummary from 'components/AccordionSummary';

/**
 * FAQ Questions that user may check.
 */
const Questions = () => (
  <AccordionGroup>
    <Accordion>
      <AccordionSummary>
        Can the we change or modify the jobs in the queue?
      </AccordionSummary>
      <AccordionDetails>
        The short answer is no. The slightly longer answer is that the data you
        are seeing is up-to-the-minute log information, and the portal is
        informational in nature.
      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary>I can’t see my job, where is it?</AccordionSummary>
      <AccordionDetails>
        Try adjusting the filters at the top of the queue page. Some of the
        filter settings may have excluded the job you are trying to locate. If
        the list of jobs is long then your job may be further down the list on
        another page of the queue table. If you still can’t locate your job
        after trying these suggestion please contact support.
      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary>
        How can I change the content of the report?
      </AccordionSummary>
      <AccordionDetails>
        The content contained in the report is driven by the selection of the
        filters. Once you have the desired list of jobs in the queue table you
        can produce a report based on those results.
      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary>
        A colleague wants access to the Portal. Can this be arranged?
      </AccordionSummary>
      <AccordionDetails>
        Sure thing. Just contact your admin and they will be able to assist your
        colleague to get access to the Portal.
      </AccordionDetails>
    </Accordion>
  </AccordionGroup>
);

export default Questions;
