import React from 'react';

import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { useGetIdentity } from 'react-admin';
import useDashboardState from './useDashboardState';
import { BarChart, LinearChart, TaskCard, FilterForm } from './components';

/**
 * Dashboard page that output information about current running task.
 */
const Dashboard = () => {
  const { identity, loading: identityLoading } = useGetIdentity();
  const cloudDirectUserRole = React.useMemo(() => {
    if (!identity) return false;

    const userType = identity['custom:user_type'] || '';
    return userType.indexOf(['admin', 'reseller', 'staff']) !== -1;
  }, [identity]);

  const { state, context } = useDashboardState();
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Card>
          <CardHeader title="Welcome to CloudDirect" />
        </Card>
      </Grid>
      <Grid item>
        <FilterForm {...context} />
      </Grid>
      {!identityLoading && (
        <Grid item container spacing={1}>
          <Grid item md={4} xs={12}>
            <TaskCard
              cloudDirectUserRole={cloudDirectUserRole}
              record={state.task}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Card>
              <CardHeader title="Linear data summary" />
              <CardContent>
                <LinearChart data={state.chartData} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={4} xs={12}>
            <Card>
              <CardHeader title="Bar data summary" />
              <CardContent>
                <BarChart data={state.chartData} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Dashboard;
