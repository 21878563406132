import React from 'react';
import { TextInput, SimpleForm, required } from 'react-admin';
import FormLayout from 'components/FormLayout';
import FormToolbar from 'components/FormToolbar';
import useConfirmSignUp from './useConfirmSignUp';

/**
 * Confirm Sign Up page is a page where user supposed to enter verification code to confirm their account.
 */
const ConfirmSignUp = () => {
  const { verify } = useConfirmSignUp();
  return (
    <FormLayout title="Confirm Sign Up">
      <SimpleForm
        variant="standard"
        save={verify}
        toolbar={<FormToolbar label="Sign Up" />}
      >
        <TextInput
          isRequired
          validate={[required()]}
          label="Email"
          type="email"
          source="email"
        />
        <TextInput
          isRequired
          validate={[required()]}
          label="Verification Code"
          type="number"
          source="code"
        />
      </SimpleForm>
    </FormLayout>
  );
};

export default ConfirmSignUp;
