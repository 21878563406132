import React from 'react';
import { PasswordInput, SimpleForm, required, minLength } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Toolbar from './Toolbar';
import { FormValues } from './types';
import useUpdatePassword from './useUpdatePassword';

const validateForm = (values: FormValues) => {
  const errors = {} as Record<string, string>;
  if (values.new_password !== values.confirm_password) {
    errors.confirm_password = 'Password does not match';
  }
  return errors;
};

/**
 * Change Password page. It allows to change a current user password.
 */
const ChangePassword = () => {
  const { updatePassword } = useUpdatePassword();
  return (
    <Card>
      <CardHeader title="Change Password" />
      <SimpleForm
        variant="standard"
        save={updatePassword}
        validate={validateForm}
        toolbar={<Toolbar />}
      >
        <PasswordInput
          isRequired
          validate={[required()]}
          label="Old Password"
          source="old_password"
        />
        <PasswordInput
          isRequired
          validate={[required(), minLength(6)]}
          label="New Password"
          source="new_password"
        />
        <PasswordInput
          isRequired
          validate={[required(), minLength(6)]}
          label="Confirm New Password"
          source="confirm_password"
        />
      </SimpleForm>
    </Card>
  );
};

export default ChangePassword;
