import { createMuiTheme } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';
import createTypography from '@material-ui/core/styles/createTypography';

const palette = createPalette({});
const typography = createTypography(palette, {
  fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
  fontSize: 14,
  htmlFontSize: 14,
});

const theme = createMuiTheme({
  typography: {
    ...typography,
    body1: {
      ...typography.body1,
      fontSize: '1rem',
    },
    h6: {
      ...typography.h6,
      fontWeight: 700,
      fontSize: '1.285714285714286rem',
      marginBottom: '1rem',
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        background: 'linear-gradient(to right, #3a3633 93%, #2a2725 100%)',
      },
    },
    MuiOutlinedInput: {
      root: {
        minHeight: 52,
      },
      input: {
        paddingTop: 4,
        paddingBottom: 4,
      },
    },
    MuiButton: {
      contained: {
        boxShadow: '0 0 0 0 rgb(34 36 38 / 15%) inset',
      },
      containedPrimary: {
        backgroundColor: '#2185d0',
        '&:hover': {
          backgroundColor: '#1678c2',
        },
      },
      outlinedPrimary: {
        background: '#e0e1e2',
        border: 'none',
        borderRadius: 4,
        color: 'rgba(0,0,0,.6)',
        '&:hover': {
          backgroundColor: '#cacbcd',
          boxShadow:
            '0 0 0 1px transparent inset, 0 0 0 0 rgb(34 36 38 / 15%) inset',
          color: 'rgba(0,0,0,.8)',
          border: 'none',
        },
      },
    },
    MuiTablePagination: {
      toolbar: {
        backgroundImage: 'linear-gradient(top, #f2f2f2 0, #fafafa 100%)',
        backgroundColor: '#eee',
      },
    },
    // Notes: we use ts-ignore to here override admin styles
    // because in case of material ui theme those properties are not declared
    // @ts-ignore
    RaSidebar: {
      drawerPaper: {
        maxWidth: 200,
      },
      paper: {
        background: 'linear-gradient(to right, #3a3633 93%, #2a2725 100%)',
      },
    },
    RaMenuItemLink: {
      root: {
        color: '#c0bbb7',
      },
      active: {
        background: 'rgba(0,0,0,.05)',
        color: '#fff',
      },
      icon: {
        color: 'inherit',
      },
    },
    RaSelectArrayInput: {
      root: {
        minWidth: 200,
      },
    },
    RaLogout: {
      menuItem: {
        color: 'rgba(0, 0, 0, 0.54)',
      },
      active: {
        background: 'rgba(0,0,0,.05)',
        color: '#fff',
      },
      icon: {
        color: 'inherit',
      },
    },
    RaAutocompleteArrayInput: {
      chipContainerOutlined: {
        margin: '2px 12px 2px 0',
      },
    },
    RaListToolbar: {
      toolbar: {
        marginTop: '1em',
        marginBottom: '1em',
        padding: 0,
        paddingLeft: '1em !important',
        paddingRight: '1em',
        border: '1px solid rgba(34, 36, 38, 0.15)',
        boxShadow: '0 1px 2px 0 rgb(34 36 38 / 15%)',
        borderRadius: 4,
        backgroundColor: '#fff',
      },
      actions: {
        minHeight: 30,
        paddingTop: 18,
        paddingBottom: 18,
      },
    },
    RaDatagrid: {
      headerCell: {
        backgroundColor: '#2a2725',
        background:
          'linear-gradient(to right, #3a3633 93%, #2a2725 100%) !important',
        color: '#fff',
        fontSize: 12,
        borderLeft: '1px solid rgba(34,36,38,.1)',
        '&:first-child': {
          borderLeft: 'none',
        },
      },
      rowCell: {
        borderLeft: '1px solid rgba(34,36,38,.1)',
        fontSize: 12,
        '&:first-child': {
          borderLeft: 'none',
        },
      },
      headerRow: {
        backgroundColor: '#eee',
        lineHeight: 1.42857143,
        fontSize: 12,
      },
      row: {
        backgroundColor: '#eee',
        lineHeight: 1.42857143,
        fontSize: 12,
      },
      rowEven: {
        backgroundImage: 'none',
        backgroundColor: '#f9f9f9',
      },
    },
    RaList: {
      content: {
        background: '#fff',
        border: '1px solid rgba(34,36,38,.15)',
        borderRadius: 4,
        textAlign: 'left',
        color: 'rgba(0,0,0,.87)',
        borderCollapse: 'separate',
        borderSpacing: 0,
        boxShadow: 'none',
      },
    },
    RaDatagridHeaderCell: {
      active: {
        color: '#fff !important',
      },
      icon: {
        color: 'inherit !important',
      },
    },
    RaPlaceholder: {
      root: {
        background: 'inherit',
      },
    },
    RaToolbar: {
      desktopToolbar: {
        background: 'inherit',
      },
    },
  },
});

export default theme;
