import React, { ReactElement } from 'react';
import { List as RaList, ListProps } from 'react-admin';
import Pagination from './Pagination';

/**
 * React admin List with override Pagination
 */
const List = (
  props: ListProps & {
    children: ReactElement;
  },
) => <RaList pagination={<Pagination />} {...props} />;

export default List;
