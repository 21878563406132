import List from './List';

/**
 * Configuration for job resource.
 */
const jobs = {
  list: List,
  options: {
    label: 'Queue',
  },
};

export default jobs;
