import React from 'react';
import { Toolbar, ToolbarProps, SaveButtonProps } from 'react-admin';
import SaveButton from 'components/SaveButton';

/**
 * Generic FormToolbar that has inside itself Save button to simplify
 * changes within FormToolbar.
 */
const FormToolbar = ({
  label,
  ...props
}: ToolbarProps & Pick<SaveButtonProps, 'label'>) => (
  <Toolbar {...(props as any)}>
    <SaveButton label={label} />
  </Toolbar>
);

export default FormToolbar;
